// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** jwtService import to get config
import jwtService from '@src/auth/jwt/jwtService';

// Loader Components
import showLoader from '@components/loader';

// API Imports
import axios from '@src/service/axios';
import { secureLs } from '@store/permission';

const config = jwtService.jwtConfig;

const initialUser = () => {
    const item = window.localStorage.getItem('userData');

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const verifyToken = createAsyncThunk('authentication/verifyToken', async () => {
    showLoader(true);
    return await axios
        .get('/token', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            showLoader(false);
            return res.data;
        })
        .catch((err) => {
            showLoader(false);
            toast.error('Session Expired!');
            return {};
        });
});

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload.userData;
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName];

            // ** Set user, accessToken to localStorage
            localStorage.setItem('userData', JSON.stringify(action.payload.userData));
            localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        },
        changeUserData: (state, action) => {
            state.userData = { ...state.userData, ...action.payload };
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        handleLogout: (state) => {
            state.userData = {};
            state[config.storageTokenKeyName] = null;

            // ** Remove user, accessToken from localStorage
            localStorage.removeItem('userData');
            localStorage.removeItem(config.storageTokenKeyName);
            secureLs.removeAll();
        },
    },

    extraReducers: (builder) => {
        builder.addCase(verifyToken.fulfilled, (state, action) => {
            // authSlice.caseReducers.handleLogin(state, action);
            state.userData = action.payload.userData;
            localStorage.setItem('userData', JSON.stringify(action.payload.userData));
        });
    },
});

export const { handleLogin, handleLogout, changeUserData } = authSlice.actions;

export default authSlice.reducer;
