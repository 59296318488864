// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getUser = createAsyncThunk('getUser', async () => {
    return await axios
        .get('/profile')
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            console.log('some errr', err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return null;
        });
});

export const user = createSlice({
    name: 'user',
    initialState: {
        user: null,
    },
    reducers: {
        handleUserChange: (state, action) => {
            state = action.payload;
        },
        removeUser: (state, action) => {
            state = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
            return;
        });
    },
});

export const { handleUserChange, removeUser } = user.actions;

export default user.reducer;
